import type { EasingTypes } from '@g360/vt-types';

import Easing, { clamp, easeInOutQuad, easeOutCubic, easeOutQuad } from './Utils';

/**
 * Don't use directly, use Animator mixin instead.
 * Engine will tick all animations that are created with Animator (and will not tick animations created with this class directly).
 */
class SimpleAnimator {
  // make this a parameter WHEN another kind of bezier is needed
  private static readonly bezier = Easing.bezier(0.42, 0, 0.58, 1);

  public finished = false;

  private readonly finishedCallback?: () => void;
  private readonly tickCallback: (progress: number) => void;
  private readonly duration: number;
  private readonly easing: EasingTypes;
  private readonly fixedTimeStep: number | undefined; // e.g. 1/60 for 60fps; if given, will ignore real time passed between frames

  private timeElapsed = 0;
  private progress = 0; // 0 - 1
  private lastTimestamp = 0;

  constructor(
    duration: number,
    easing: EasingTypes,
    tickCallback: (progress: number) => void,
    finishedCallback?: () => void,
    fixedTimeStep?: number
  ) {
    this.finishedCallback = finishedCallback;
    this.tickCallback = tickCallback;
    this.duration = duration;
    this.easing = easing;
    this.fixedTimeStep = fixedTimeStep;
    this.lastTimestamp = performance.now();
  }

  public static getEasedValue(value: number, easing: EasingTypes) {
    let easedProgress = value; // "linear"

    if (easing === 'easeOutQuad') {
      easedProgress = easeOutQuad(value);
    } else if (easing === 'easeOutCubic') {
      easedProgress = easeOutCubic(value);
    } else if (easing === 'easeInOutQuad') {
      easedProgress = easeInOutQuad(value);
    } else if (easing === 'bezier') {
      easedProgress = SimpleAnimator.bezier(value);
    }

    return easedProgress;
  }

  public stop() {
    if (this.finished) return;
    if (this.finishedCallback) {
      this.finishedCallback();
    }
    this.finished = true;
  }

  public tick() {
    if (this.finished) return;

    let delta: number;

    if (this.fixedTimeStep) {
      delta = this.fixedTimeStep * 1000; // convert to milliseconds
    } else {
      const now = performance.now();
      delta = now - this.lastTimestamp;
      this.lastTimestamp = now;
    }

    this.timeElapsed += delta;
    this.progress = this.duration === 0 ? 1 : clamp(this.timeElapsed / this.duration);

    this.tickCallback(SimpleAnimator.getEasedValue(this.progress, this.easing));

    if (this.progress >= 1) {
      this.finished = true;
      if (this.finishedCallback) {
        this.finishedCallback();
      }
    }
  }
}

export default SimpleAnimator;
