import type {
  AnyHotSpotConfig,
  AppContext,
  AssetConfig,
  BlurMaskData,
  ColorCorrectionSettings,
  Degree,
  EasingTypes,
  EngineConfig,
  EngineProps,
  GuidedViewingConfig,
  HotSpot2DType,
  HotSpot3DType,
  HotSpotConfig,
  MeasureModePlatform,
  Milliseconds,
  NavigationMode,
  Pixel,
  Radian,
  SceneConfig,
  ScenePos,
  Size,
  Subscription,
  SyncData,
  TourConfig,
  TourEventSource,
  WatermarkConfig,
} from '@g360/vt-types';
import type { Analytics } from '@g360/vt-utils';
import { linearScale } from '@g360/vt-utils/';
import isEqual from 'lodash/isEqual';
import { Mixin } from 'ts-mixer';

import ContrastChecker from './common/ContrastChecker';
import {
  DEFAULT_ASSET_CONFIG,
  DEFAULT_ENGINE_CONFIG,
  DEFAULT_FOV_DEG,
  MAX_FOV_RAD,
  MODE_SWITCH_WAIT_TIME,
  MODE_SWITCH_WAIT_TIMEOUT,
} from './common/Globals';
import RendererDebug from './common/RendererDebug';
import ScenePreloader from './common/ScenePreloader';
import type { TourConfigService } from './common/services/TourConfigService';
import Utils, { clamp } from './common/Utils';
import GestureController from './controllers/GestureController';
import GuestController from './controllers/GuidedViewing/GuestController';
import MeasureToolController from './controllers/MeasureTool/MeasureToolController';
import type { IController } from './controllers/types';
import DebugControls from './DebugControls';
import Animator from './mixins/Animator';
import Camera from './mixins/Camera';
import ControllerMixin from './mixins/ControllerMixin';
import EventEmitter from './mixins/EventEmitter';
import GuidedViewing from './mixins/GuidedViewing';
import Renderer from './mixins/Renderer';
import type BlurProgram from './programs/BlurProgram';
import type HotSpotProgram from './programs/HotSpotProgram';
import type HotSpot from './programs/HotSpotProgram/HotSpot';
import type HotSpotProgram3D from './programs/HotSpotProgram3D';
import type HotSpot3D from './programs/HotSpotProgram3D/HotSpot3D';
import type VideoCaptionProgram from './programs/VideoCaptionProgram';
import type {
  AnyProgram,
  CaptionParams,
  MeasureToolDebugParams,
  ProgramName,
  TransitionOptions,
} from './types/internal';

class Engine extends Mixin(EventEmitter, Animator, GuidedViewing, Camera, ControllerMixin) {
  public watermarkInterrupted = false;
  public animatingTransition = false;
  public sceneTransition = false;
  public overlay = false;
  /** Is preview or equirect layout loaded and ready to render */
  public isSceneLayoutReady = false;
  public usingNewHotSpots: boolean;
  public hasInfoHotSpots = false;
  public appContext: AppContext = 'standalone';
  public isEngineReady = false;

  protected assetConfig: AssetConfig;
  protected engineConfig: EngineConfig;
  protected _fov = Utils.toRad(DEFAULT_FOV_DEG);
  protected _pitch: Radian = 0;
  protected _yaw: Radian = 0;
  protected _zoomFloorPlan = 0;
  protected activeHotSpot: HotSpot | null = null;
  protected canvas: HTMLCanvasElement;
  protected contrastChecker = new ContrastChecker('#231720');
  protected controller: IController;
  /** 0..1 fov percentage from currently allowed min..max */
  protected zoomRatio = 0;
  protected tourEventSource: TourEventSource = 'DOMEvents';
  protected isSceneMoveAnimated = false;
  protected debugControls?: DebugControls;
  protected guidedViewingConfig: GuidedViewingConfig;
  protected boundingRect: DOMRect;
  protected tourConfigService: TourConfigService;
  protected analytics?: Analytics;
  protected minPitchClamp: number | undefined;
  protected maxPitchClamp: number | undefined;
  protected isVerificationNeeded = false;

  private readonly scenePreloader?: ScenePreloader;
  private renderer: Renderer;
  private modeSwitchInitiatedTime: Milliseconds = 0;
  /** corresponds to zoom direction */
  private modeSwitchDirection: 'up' | 'down' | null = null;

  private initialized = false;

  private _activeSceneConfig: SceneConfig = {} as SceneConfig;
  private _activeSubSceneConfig: SceneConfig | null = null;

  // @ts-expect-error Mixin doesn't correctly handle multiple abstract classes with abstract get/set
  public get activeSceneConfig() {
    return this._activeSceneConfig;
  }

  public set activeSceneConfig(config: SceneConfig) {
    this._activeSceneConfig = config;
    this.emit('scene.mainscene.change', config);
  }

  public get activeSubSceneConfig() {
    return this._activeSubSceneConfig;
  }

  public set activeSubSceneConfig(config: SceneConfig | null) {
    this._activeSubSceneConfig = config;
    this.emit('scene.subscene.change', config);
  }

  /** Store UI configuration before entering measure mode, to
   * have the possibility to restore it after exiting measure mode
   */
  private preMeasureModeConfig: {
    navigationMode: NavigationMode;
    renderHotSpots: boolean;
  } | null = null;

  /** Stores the last visited subScene sceneKey of the corresponding main scene */
  private lastVisitedSubScenes: { [mainSceneKey: string]: string } = {};

  constructor({
    canvas,
    tourConfigService,
    assetConfig = DEFAULT_ASSET_CONFIG,
    engineConfig = DEFAULT_ENGINE_CONFIG,
    controller = new GestureController(),
    analytics,
  }: EngineProps<TourConfigService, IController, Analytics>) {
    super();
    if (!tourConfigService) throw new Error('tourConfig or configService is required');
    this.tourConfigService = tourConfigService;

    const newRenderer = new Renderer(this, canvas, tourConfigService, assetConfig, engineConfig);
    this.renderer = newRenderer;

    this.setAnalytics(analytics);
    if (__DEV_PANEL__) {
      this.debugControls = new DebugControls(newRenderer.actualCameraPos);
    }

    this.canvas = canvas;

    this.guidedViewingConfig = {
      enabled: false,
      isInControlOfTour: true,
      onHostEmit: () => undefined,
      onGuestReceive: () => undefined,
    };

    newRenderer.resizeRenderingBuffer();

    newRenderer.oldGeometry = this.tourConfigService.configStructureType === 'layers'; // used for workaround in DepthProgram for transition
    this.usingNewHotSpots = this.tourConfigService.getVersion() >= 4;

    this.hasInfoHotSpots = Object.values(this.tourConfigService.scenes).some((scene) =>
      scene.hotSpots?.some((hotSpot) => hotSpot.type === 'hotspot-info')
    );

    this.assetConfig = { ...DEFAULT_ASSET_CONFIG, ...assetConfig };
    this.engineConfig = { ...DEFAULT_ENGINE_CONFIG, ...engineConfig };

    // -------------------------------------------------------------------------
    // NEW HOTSPOTS
    // -------------------------------------------------------------------------
    // @todo -- rem, only for debug
    // -------------------------------------------------------------------------
    if (RendererDebug.getDebugStringParam('hotspotDataEncoded', '')) {
      this.usingNewHotSpots = true; // if new hotspot data found in URl, then use new hotspots

      // overwrite pano yaw offset (camera[3]) with values from new hotspot data
      const json = RendererDebug.tryGetHotSpotDataFromUrlParamSync();
      if (this.tourConfigService.tourConfig && json) {
        Object.values(this.tourConfigService.scenes).forEach((scene) => {
          if (json.scenes[scene.sceneKey].camera && this.tourConfigService.scenes[scene.sceneKey].camera) {
            // const og = this.tourConfigService.scenes[scene.sceneKey].camera;
            this.tourConfigService.scenes[scene.sceneKey].camera[3] = json.scenes[scene.sceneKey].camera[3];
            // console.log(`set scene rotation to ${Math.round(json.scenes[scene.sceneKey].camera[3])}° for scene ${scene.sceneKey}`,   `from some json we found in the URL. (og=${og})`  );
          }
        });
      }
    }

    newRenderer.blurEditorEnabled = engineConfig.blurMode;
    this.activeSceneConfig = this.tourConfigService.getInitialSceneConfig();
    this.boundingRect = this.getCanvasBoundingClientRect();
    this.controller = controller;

    if (__USE_VIDEO_EDITOR__) {
      this.scenePreloader = new ScenePreloader(this.tourConfigService, this.assetConfig, this);
    }

    this.getActiveSceneKey = this.getActiveSceneKey.bind(this);
    this.getCanvasSize = this.getCanvasSize.bind(this);
  }

  // public API

  // #region Getters and Setters

  public get pitch(): Radian {
    return this._pitch;
  }

  public set pitch(pitch: number) {
    this.cameraMoved ||= pitch !== this._pitch; // this can set to true, never to false
    this._pitch = pitch;
  }

  public get yaw(): Radian {
    return this._yaw;
  }

  protected set yaw(yaw: number) {
    this.cameraMoved ||= yaw !== this._yaw;
    this._yaw = yaw;
  }

  public get fov(): Radian {
    return this._fov;
  }

  public set fov(fov: number) {
    this.cameraMoved ||= fov !== this._fov;
    this._fov = fov;
  }

  /**
   * zoom uses the same input as fov, but is used for floorplan3D zooming
   */
  public get zoomFloorPlan(): number {
    return this._zoomFloorPlan;
  }

  protected set zoomFloorPlan(zoom: number) {
    this.cameraMoved ||= zoom !== this._zoomFloorPlan;
    this._zoomFloorPlan = zoom;
  }

  /** Chrome CPU mode, some alternatives in new-transition rendering must be used */
  public get safeMode(): boolean {
    return this.renderer.safeMode;
  }

  /** Chrome CPU mode, some alternatives in new-transition rendering must be used */
  public set safeMode(value: boolean) {
    this.renderer.safeMode = value;
  }

  public get frameBuffer(): WebGLFramebuffer | null | undefined {
    return this.renderer.frameBuffer;
  }

  public set frameBuffer(value: WebGLFramebuffer | null | undefined) {
    this.renderer.frameBuffer = value;
  }

  public get renderBuffer(): WebGLRenderbuffer | null | undefined {
    return this.renderer.frameBuffer;
  }

  public set renderBuffer(value: WebGLRenderbuffer | null | undefined) {
    this.renderer.renderBuffer = value;
  }

  public get fbTexture1(): WebGLTexture | null | undefined {
    return this.renderer.fbTexture1;
  }

  public set fbTexture1(value: WebGLTexture | null | undefined) {
    this.renderer.fbTexture1 = value;
  }

  public get fbTexture2(): WebGLTexture | null | undefined {
    return this.renderer.fbTexture2;
  }

  public set fbTexture2(value: WebGLTexture | null | undefined) {
    this.renderer.fbTexture2 = value;
  }

  /** Only draw to FB [Frame Buffer] when the camera moves, otherwise reuse the last drawn frame
   * This flag is required to draw the first frame when measure mode is turned on */
  public get needsToDrawFb(): boolean {
    return this.renderer.needsToDrawFb;
  }

  /** Only draw to FB [Frame Buffer] when the camera moves, otherwise reuse the last drawn frame
   * This flag is required to draw the first frame when measure mode is turned on */
  public set needsToDrawFb(value: boolean) {
    this.renderer.needsToDrawFb = value;
  }

  /** true if any camera angles were changed in this frame */
  public get cameraMoved(): boolean {
    return this.renderer.cameraMoved;
  }

  /** true if any camera angles were changed in this frame */
  public set cameraMoved(value: boolean) {
    this.renderer.cameraMoved = value;
  }

  /** Store whether the camera moved on the instance for callbacks */
  public get nextDrawMoved(): boolean {
    return this.renderer.nextDrawMoved;
  }

  /** Store whether the camera moved on the instance for callbacks */
  public set nextDrawMoved(value: boolean) {
    this.renderer.nextDrawMoved = value;
  }

  /** non-eased value */
  public get transitionProgress(): number {
    return this.renderer.transitionProgress;
  }

  /** non-eased value */
  public set transitionProgress(value: number) {
    this.renderer.transitionProgress = value;
  }

  /** are the scenes we are transitioning from-to are in same building and floor */
  public get transitionConnected(): boolean {
    return this.renderer.transitionConnected;
  }

  /** are the scenes we are transitioning from-to are in same building and floor */
  public set transitionConnected(value: boolean) {
    this.renderer.transitionConnected = value;
  }

  /** last wall */
  public get transitionWallEncounterPercentB(): number {
    return this.renderer.transitionWallEncounterPercentB;
  }

  /** last wall */
  public set transitionWallEncounterPercentB(value: number) {
    this.renderer.transitionWallEncounterPercentB = value;
  }

  /** RAD angle of the transition: where the user is looking; 0 means straight ahead to the next pano,
   * 180° means directly to the last pano */
  public get transitionDirection(): number {
    return this.renderer.transitionDirection;
  }

  /** RAD angle of the transition: where the user is looking; 0 means straight ahead to the next pano,
   * 180° means directly to the last pano */
  public set transitionDirection(value: number) {
    this.renderer.transitionDirection = value;
  }

  /** Draw one frame after the camera has finished moving to render max lvl tiles */
  public get shouldDrawAfterMove(): boolean {
    return this.renderer.shouldDrawAfterMove;
  }

  /** Draw one frame after the camera has finished moving to render max lvl tiles */
  public set shouldDrawAfterMove(value: boolean) {
    this.renderer.shouldDrawAfterMove = value;
  }

  public get isInTransition(): boolean {
    return this.renderer.isInTransition;
  }

  public set isInTransition(value: boolean) {
    this.renderer.isInTransition = value;
  }

  public get renderMode(): 'pano' | 'floorplan3D' {
    return this.renderer.renderMode;
  }

  public set renderMode(value: 'pano' | 'floorplan3D') {
    this.renderer.renderMode = value;
  }

  public get vendor(): string {
    return this.renderer.vendor;
  }

  public set vendor(value: string) {
    this.renderer.vendor = value;
  }

  public get isPerformanceEnabled(): boolean {
    return this.renderer.isPerformanceEnabled;
  }

  public set isPerformanceEnabled(value: boolean) {
    this.renderer.isPerformanceEnabled = value;
  }

  public get isCustomMeasurementEnabled(): boolean {
    return this.renderer.isCustomMeasurementEnabled;
  }

  public set isCustomMeasurementEnabled(value: boolean) {
    this.renderer.isCustomMeasurementEnabled = value;
  }

  // #endregion

  public static createComboImage(tilePaths: string[], onCreated: (image: HTMLImageElement) => void): void {
    Renderer.createComboImage(tilePaths, onCreated);
  }

  /** Initialize engine/rendering and start loading assets */
  public start(): void {
    this.renderer.init(this.debugControls);
    this.loadSceneConfig(this.activeSceneConfig);
    this.loadInitialView(this.activeSceneConfig);
    this.initializeController(this.controller);
    this.controller.connect();

    // watch the TourConfigService for changes
    this.watchConfigChanges();

    this.initialized = true;
  }

  public getTourConfigService(): TourConfigService {
    return this.tourConfigService;
  }

  public emitInfoHotSpotRelease(): void {
    this.emit('hotspots.info.release', null);
  }

  public getTourEventSource(): TourEventSource {
    return this.tourEventSource;
  }

  public geActualCameraPos(inverse = true): number[] {
    if (inverse) return this.renderer.actualCameraPos.map((pos) => Math.round(-pos));

    return this.renderer.actualCameraPos;
  }

  public destroy(): void {
    this.controller.disconnect();
    this.renderer.destroy();
    this.destroyEventEmitter();
  }

  public async loadScene({
    sceneKey,
    pitch,
    yaw,
  }: {
    /** sceneKey in case of main scene, [mainSceneKey, subSceneKey] in case of subScene */
    sceneKey: string | [string, string];
    pitch: Degree | undefined;
    yaw: Degree | undefined;
  }): Promise<void> {
    let scenePromise: Promise<void>;
    if (!Array.isArray(sceneKey)) {
      scenePromise = this.loadSceneConfig(this.tourConfigService.getSceneConfigByKey(sceneKey));
    } else {
      const mainScene = this.tourConfigService.getSceneConfigByKey(sceneKey[0]);
      const subScene = this.tourConfigService.getSceneConfigByKey(sceneKey);

      scenePromise = this.loadSubSceneConfig(mainScene, subScene);
    }

    if (yaw !== undefined) {
      this.yaw = yaw ? Utils.toRad(yaw) : 0;
    }
    if (pitch !== undefined) {
      this.pitch = pitch ? Utils.toRad(pitch) : 0;
    }

    this.render();
    await scenePromise;
  }

  /**
   * Load new {@link TourConfig} and set basePath.
   * Will use already set custom branding settings as a priority. Use {@link resetBrandingSettings} to remove them
   * and load all the settings from provided tourConfig
   * */
  public loadTourConfig(tourConfig: TourConfig, assetConfig: AssetConfig = DEFAULT_ASSET_CONFIG): void {
    this.fov = Utils.toRad(DEFAULT_FOV_DEG);
    this.pitch = 0;
    this.yaw = 0;

    this.tourConfigService.reload(tourConfig);
    this.assetConfig = assetConfig;

    this.renderer.destroy();
    this.renderer.init(this.debugControls);

    if (this.guidedViewingConfig.isInControlOfTour) {
      this.switchController(new GestureController());
    } else {
      this.switchController(new GuestController());
    }

    this.activeSceneConfig = this.tourConfigService.getInitialSceneConfig();

    this.renderer.previousSceneConfig = undefined;
    this.renderer.loadConfig(this.activeSceneConfig);
    this.loadInitialView(this.activeSceneConfig);
  }

  public getController(): IController {
    return this.controller;
  }

  public getAssetConfig(): AssetConfig {
    return this.assetConfig;
  }

  /** Gets scene yaw position in radians, this is the internal engine angle, not relative to the layout */
  public getYaw(): Radian {
    return this.yaw;
  }

  /** Gets scene pitch position in radians, 0 is at the center of layout image */
  public getPitch(): Radian {
    return this.pitch;
  }

  /** Gets scene yaw position in degrees, relative to the layout, use this for initial view */
  public getLayoutYawDeg(): Degree {
    return Utils.toDeg(this.yaw) + (this.getActiveSubSceneConfig() ?? this.getActiveSceneConfig()).camera[3];
  }

  /** Gets scene fov position in radians */
  public getFov(): Radian {
    return this.fov || Utils.toRad(DEFAULT_FOV_DEG);
  }

  // eslint-disable-next-line class-methods-use-this
  public getMaxFov(): Radian {
    return MAX_FOV_RAD;
  }

  /** Gets the calibration offset from json data for active scene */
  public getYawOffset(): Radian {
    return Utils.toRad((this.getActiveSubSceneConfig() ?? this.getActiveSceneConfig()).camera[3]);
  }

  /** Get current pitch value with pitch limit applied */
  public getClampedPitch(): Radian {
    const min = this.minPitchClamp ?? this.tourConfigService.minPitch;
    const max = this.maxPitchClamp;
    return Utils.clampPitch(this.pitch, this.fov, this.getCanvasSize(), min, max);
  }

  /** Gets currently active scene config */
  public getActiveSceneConfig(): SceneConfig {
    return this.activeSceneConfig;
  }

  setActiveSceneConfig(sceneConfig: SceneConfig) {
    this.activeSceneConfig = sceneConfig;
  }

  getActiveSubSceneConfig(): SceneConfig | null {
    return this.activeSubSceneConfig;
  }

  setActiveSubSceneConfig(sceneConfig: SceneConfig | null) {
    this.activeSubSceneConfig = sceneConfig;
  }

  public getActiveSceneKey(): string {
    return this.activeSceneConfig.sceneKey || this.tourConfigService.getFirstSceneKey();
  }

  /** Loads scene by provided scene config object, except if transition already is in progress */
  public async loadSceneConfig(sceneConfig: SceneConfig, opts?: TransitionOptions): Promise<void> {
    if (this.initialized && this.activeSceneConfig && this.activeSceneConfig.sceneKey === sceneConfig.sceneKey) return;

    this.activeSceneConfig = sceneConfig;

    // If the target scene has been previously visited, load the last visited subScene
    // of that scene group
    const lastVisitedSubSceneKey = this.lastVisitedSubScenes[sceneConfig.sceneKey];
    const lastVisitedSubScene = lastVisitedSubSceneKey
      ? this.tourConfigService.scenes[sceneConfig.sceneKey].subScenes?.[lastVisitedSubSceneKey]
      : undefined;

    if (lastVisitedSubScene) {
      this.loadSubSceneConfig(sceneConfig, lastVisitedSubScene, opts);
      return;
    }

    this.activeSubSceneConfig = null;

    // TODO: not sure why this was necessary for GV, but in standalone player and editors this is annoying
    if (this.isInGuidedViewing() && this.isSceneMoveAnimated) return;
    if (this.animatingTransition) return;

    if (this.isSceneMoveAnimated) this.stopAnimation('look');

    if (sceneConfig) {
      this.activeSceneConfig = sceneConfig;

      const startYawRad: Radian | undefined = opts?.startYaw !== undefined ? Utils.toRad(opts.startYaw) : undefined;

      await this.renderer.loadConfig(sceneConfig, {
        duration: opts?.duration,
        onBeforeTransitionEnd: () => {
          if (startYawRad !== undefined) this.yaw = startYawRad;
        },
      });
    } else {
      throw new Error('Invalid scene config!');
    }
  }

  /** Loads scene by provided scene config object, except if transition already is in progress */
  public async loadSubSceneConfig(
    mainSceneConfig: SceneConfig,
    subSceneConfig: SceneConfig,
    opts?: TransitionOptions
  ): Promise<void> {
    if (!this.activeSubSceneConfig && subSceneConfig.sceneKey === this.activeSceneConfig.sceneKey) return;
    if (this.activeSubSceneConfig?.sceneKey === subSceneConfig.sceneKey) return;

    this.activeSceneConfig = mainSceneConfig;
    this.activeSubSceneConfig = subSceneConfig;

    // TODO: not sure why this was necessary for GV, but in standalone player and editors this is annoying
    if (this.isInGuidedViewing() && this.isSceneMoveAnimated) return;
    if (this.animatingTransition) return;

    if (this.isSceneMoveAnimated) this.stopAnimation('look');

    if (mainSceneConfig && subSceneConfig) {
      if (mainSceneConfig !== this.activeSceneConfig) {
        this.activeSceneConfig = mainSceneConfig;
      }
      this.activeSubSceneConfig = subSceneConfig;

      const startYawRad: Radian | undefined = opts?.startYaw !== undefined ? Utils.toRad(opts.startYaw) : undefined;

      await this.renderer.loadConfig([mainSceneConfig, subSceneConfig], {
        duration: opts?.duration,
        onBeforeTransitionEnd: () => {
          if (startYawRad !== undefined) this.yaw = startYawRad;
        },
      });
      this.lastVisitedSubScenes[mainSceneConfig.sceneKey] = subSceneConfig.sceneKey;
    } else {
      throw new Error('Invalid scene config!');
    }
  }

  /** Update previousSceneConfig in accordance to new sceneGroups */
  public async handleSceneGroupChange() {
    const prev = this.renderer.previousSceneConfig;
    if (!prev || !this.tourConfigService.tourConfig.sceneGroups) return;

    // Update last visited map, to keep the last visited subScene of the scene group
    Object.entries(this.lastVisitedSubScenes).forEach(([mainSceneKey, subSceneKey]) => {
      const sceneGroupIdx =
        this.tourConfigService.tourConfig.sceneGroups?.findIndex((group) => group.includes(mainSceneKey)) ?? -1;

      if (sceneGroupIdx === -1) return;
      const group = this.tourConfigService.tourConfig.sceneGroups?.[sceneGroupIdx];

      if (!group) return;
      if (group[0] === mainSceneKey) return;

      this.lastVisitedSubScenes[group[0]] = subSceneKey;
      delete this.lastVisitedSubScenes[mainSceneKey];
    });

    const prevWasSubScene = Array.isArray(prev);
    const prevMainScene = prevWasSubScene ? prev[0] : prev;

    // Find a sceneGroup where the previous main scene is no longer the mainScene
    const sceneGroupIdx =
      this.tourConfigService.tourConfig.sceneGroups?.findIndex(
        (group) => group.includes(prevMainScene.sceneKey) && group[0] !== prevMainScene.sceneKey
      ) ?? -1;

    if (sceneGroupIdx === -1) {
      // Update active scene because hotspot targets could have changed
      this.activeSceneConfig = this.tourConfigService.scenes[prevMainScene.sceneKey];
      await this.renderer.prepHotSpotProgram(this.activeSceneConfig);
      return;
    }

    const sceneGroup = this.tourConfigService.tourConfig.sceneGroups[sceneGroupIdx];
    const newMainSceneKey = sceneGroup[0];
    const newMainScene = this.tourConfigService.scenes[newMainSceneKey];

    this.activeSceneConfig = newMainScene;
    await this.renderer.prepHotSpotProgram(newMainScene);

    const idxOfPrevTargetScene = sceneGroup.findIndex(
      (sceneKey) => sceneKey === (prevWasSubScene ? prev[1].sceneKey : prev.sceneKey)
    );

    if (idxOfPrevTargetScene === -1) return;

    const subScenes = this.tourConfigService.scenes[newMainSceneKey].subScenes;
    if (!subScenes) return;

    const newSubScene = subScenes[sceneGroup[idxOfPrevTargetScene]];

    this.activeSubSceneConfig = newSubScene ?? null;

    this.renderer.previousSceneConfig = newSubScene ? [newMainScene, newSubScene] : newMainScene;
  }

  public addHotSpot(newHotSpotConfig: AnyHotSpotConfig, sceneKey: string): void {
    const combinedSceneKey = this.tourConfigService.getNestedSceneLocation(sceneKey);
    const sceneConfig = this.tourConfigService.getSceneConfigByKey(combinedSceneKey);

    if (sceneConfig.hotSpots) {
      sceneConfig.hotSpots.push(newHotSpotConfig);
    } else {
      sceneConfig.hotSpots = [newHotSpotConfig];
    }

    this.renderer.updateHotSpotProgram(newHotSpotConfig, sceneKey, 'add');
  }

  public updateHotSpot(updatedHotSpotConfig: AnyHotSpotConfig, sceneKey: string): void {
    const combinedSceneKey = this.tourConfigService.getNestedSceneLocation(sceneKey);
    const sceneConfig = this.tourConfigService.getSceneConfigByKey(combinedSceneKey);

    if (!sceneConfig.hotSpots) return;

    sceneConfig.hotSpots = sceneConfig.hotSpots.map((hotSpotConfig) => {
      if (hotSpotConfig.id === updatedHotSpotConfig.id) {
        return updatedHotSpotConfig;
      }

      return hotSpotConfig;
    });

    this.renderer.updateHotSpotProgram(updatedHotSpotConfig, sceneKey, 'update');
  }

  public deleteHotSpot(deletedHotSpotConfig: AnyHotSpotConfig, sceneKey: string): void {
    const combinedSceneKey = this.tourConfigService.getNestedSceneLocation(sceneKey);
    const sceneConfig = this.tourConfigService.getSceneConfigByKey(combinedSceneKey);

    if (!sceneConfig.hotSpots) return;

    sceneConfig.hotSpots = sceneConfig.hotSpots.filter((hotSpotConfig) => hotSpotConfig.id !== deletedHotSpotConfig.id);

    this.renderer.updateHotSpotProgram(deletedHotSpotConfig, sceneKey, 'delete');

    if (deletedHotSpotConfig.id === this.activeHotSpot?.originalConfig.id) {
      this.activeHotSpot = null;
    }

    this.emit('hotspots.info.delete', deletedHotSpotConfig as HotSpotConfig);
  }

  /** @todo check the usage of the function probably we don't need it in favour of switchController */
  public toggleCanvasEvents(active: boolean): void {
    if (active && this.guidedViewingConfig.isInControlOfTour) {
      this.controller.connect();
    } else {
      this.controller.disconnect();
    }
  }

  /**
   * Loads scene by scene key, will fall back to the first valid scene if key is invalid, see {@link getFirstSceneKey}
   */
  public loadSceneKey(sceneKey: string | [string, string], opts?: TransitionOptions): Promise<void> {
    if (Array.isArray(sceneKey)) {
      if (!this.tourConfigService.tourConfig.sceneGroups) {
        throw new Error('Trying to navigate to a subScene without sceneGroups defined in the tourConfig');
      }
      // This is a subScene
      const mainScene = this.tourConfigService.getSceneConfigByKey(sceneKey[0]);
      const subScene = this.tourConfigService.getSceneConfigByKey(sceneKey);
      return this.loadSubSceneConfig(mainScene, subScene, opts);
    }

    const sceneConfig = this.tourConfigService.getSceneConfigByKey(sceneKey);
    return this.loadSceneConfig(sceneConfig, opts);
  }

  /** Check if engine is ready to accept API calls */
  public isReady(): boolean {
    return this.isEngineReady;
  }

  /** Is scene layout (preview or equirect) loaded and ready to render */
  public isSceneReady(): boolean {
    return this.isSceneLayoutReady;
  }

  /** Emit the event when scene gets hovered in the external source - player, editor or wherever */
  public emitSceneHoverEvent(sceneKey: string | null): void {
    this.emit('hotspots.scene.hover', sceneKey);
  }

  public getCanvasBoundingClientRect(): DOMRect {
    return this.canvas.getBoundingClientRect();
  }

  public getCanvasSize(): Size<Pixel> {
    return { width: this.canvas.clientWidth, height: this.canvas.clientHeight };
  }

  public getCanvasElement(): HTMLCanvasElement {
    return this.canvas;
  }

  public setBlurMasks(sceneKey: string, intensity: number, masks: BlurMaskData[], highQuality: boolean): void {
    const blurProgram = this.getProgram<BlurProgram>('BlurProgram');
    if (blurProgram) {
      blurProgram.setBlurMasksAndRegenerateBlurs(sceneKey, intensity, masks, highQuality);
      this.render();
    }
  }

  public getBlurMaskPngDataUrl(sceneKey: string, width: number): Promise<string> {
    // eslint-disable-next-line consistent-return
    return new Promise((resolve, reject) => {
      const blurProgram = this.getProgram<BlurProgram>('BlurProgram');
      if (blurProgram) {
        return blurProgram.getBlurMaskPngDataUrl(sceneKey, width).then((png) => {
          resolve(png);
        });
      }
      reject();
    });
  }

  public getBlurMaskPngBlob(sceneKey: string, width: number): Promise<Blob> {
    // eslint-disable-next-line consistent-return
    return new Promise((resolve, reject) => {
      const blurProgram = this.getProgram<BlurProgram>('BlurProgram');
      if (blurProgram) {
        return blurProgram.getBlurMaskPngBlob(sceneKey, width).then((pngBlob) => {
          resolve(pngBlob);
        });
      }
      reject();
    });
  }

  // For blur editor (when Engine is started with blur mode on)
  // is used to turn off blurs while in transition or for any reason in the editor
  // level 0 - for renderer
  // level 1 - for editor
  public toggleBlurmaskRendering(on: boolean, level: number): void {
    const blurProgram = this.getProgram<BlurProgram>('BlurProgram');
    if (blurProgram) {
      if (level === 0) blurProgram.visibleA = on;
      else blurProgram.visibleB = on;

      this.render();
    }
  }

  // Remote viewing api

  public initGuidedViewing(config: GuidedViewingConfig): void {
    this.guidedViewingConfig = { ...config };
    this.startGuidedViewingObservers();
    this.setTourEventSource(config.isInControlOfTour ? 'DOMEvents' : 'RemoteHost');
    this.guidedViewingConfig.enabled = true;
    this.emit('remoteSyncReady');
  }

  /**
   * Check if guided viewing is enabled and initialized
   * @note Use the remoteSyncReady event to initialize and this to check if
   * already initialized after the subscription so you don't miss the event
   * */
  public isInGuidedViewing = (): boolean | undefined => this.guidedViewingConfig?.enabled;

  public toggleGuidedViewingHostState(isInControlOfTour = true): void {
    this.guidedViewingConfig = { ...this.guidedViewingConfig, isInControlOfTour };
    this.setTourEventSource(isInControlOfTour ? 'DOMEvents' : 'RemoteHost');
  }

  /** Animate camera to a specific position */
  public cameraAnimateTo(
    targetPos: ScenePos,
    animType = 'anim',
    duration?: number,
    easing?: EasingTypes
  ): Promise<unknown> {
    if (this.animatingTransition || this.isSceneMoveAnimated) return Promise.resolve(true);
    if (this.sceneTransition) return Promise.resolve(true);

    const startPos = { pitch: this.pitch, yaw: this.yaw, fov: this.fov };

    if (isEqual(startPos, targetPos)) return Promise.resolve(true);

    return new Promise((resolve) => {
      // TODO(uzars): need to handle types better, possibly use template literal types / unions?
      this.isSceneMoveAnimated = true;

      if (animType === 'ping') {
        this.emit(`scene.ping.start`, { pitch: this.pitch, yaw: this.yaw }, targetPos, 0);
      } else {
        this.emit(`scene.anim.start`, { pitch: this.pitch, yaw: this.yaw }, targetPos, duration ?? 0);
      }

      this.startAnimation(
        'look',
        (progress) => {
          this.pitch = linearScale(progress, [0, 1], [startPos.pitch, targetPos.pitch]);
          this.yaw = linearScale(progress, [0, 1], [startPos.yaw, targetPos.yaw]);
          this.fov = linearScale(progress, [0, 1], [startPos.fov, targetPos.fov || startPos.fov]);

          this.emit(
            animType === 'ping' ? `scene.ping.update` : `scene.anim.update`,
            { pitch: this.pitch, yaw: this.yaw, fov: this.fov },
            targetPos,
            progress * 100
          );
          this.emit('scene.move.update', { pitch: this.pitch, yaw: this.yaw, fov: this.fov });

          this.renderer.promiseOneFrame();
        },
        () => {
          this.emit(
            animType === 'ping' ? `scene.ping.end` : `scene.anim.end`,
            { pitch: this.pitch, yaw: this.yaw, fov: this.fov },
            targetPos,
            100
          );
          this.emit('scene.move.end');

          this.renderer.promiseOneFrame().then(async () => {
            // This is to trigger the one additional frame render needed to check if the scene is moving
            await this.renderer.promiseOneFrame();
            resolve(true);
            this.isSceneMoveAnimated = false;
          });
        },
        easing ?? 'easeOutQuad',
        duration
      );
    });
  }

  /** Change layout image BitMap Options in runtime */
  public setEquirectOptions({ primaryOptions, secondaryOptions }): void {
    if (this.assetConfig.equirectAssets) {
      this.assetConfig.equirectAssets.primaryOptions = primaryOptions;
      this.assetConfig.equirectAssets.secondaryOptions = secondaryOptions;
      this.updateEquirectOptions();
    }
  }

  public onResize(): void {
    // NOTE(uzars): if stopAnimation is called in recording mode it will cancel the first autoplay animation.
    // For projects with only one animation this will crash the ffmpeg as there are no rendered frames.
    if (!this.engineConfig.recordingMode) this.stopAnimation('look');

    this.fov = linearScale(this.zoomRatio, [1, 0], Utils.getFovBoundary(this.getCanvasSize()));
    this.boundingRect = this.getCanvasBoundingClientRect();
    this.renderer.resizeRenderingBuffer();
    this.renderer.resizeFramebufferTextures();
    this.renderer.clampView();
    this.renderer.setOptimalLevel();
  }

  public setMeasureDebugParams(params: MeasureToolDebugParams): void {
    this.renderer.changeMeasureDebugParams(params);
  }

  public setNewDepthMap(file: File): void {
    this.renderer.changeDepthMap(file);
  }

  public setNewNormalMap(file: File): void {
    this.renderer.changeNormalMap(file);
  }

  public setNewEdgeMap(file: File): void {
    this.renderer.changeEdgeMap(file);
  }

  public async toggleMeasureMode(newState: boolean): Promise<MeasureModePlatform | null> {
    const subscriptions: Subscription[] = [];

    if (newState) {
      // Custom controller to lock camera while dragging measure points
      const newController = new MeasureToolController();
      subscriptions.push(
        this.subscribe('measuretool.camera.lock', () => {
          newController.panDisabled = true;
        })
      );
      subscriptions.push(
        this.subscribe('measuretool.camera.unlock', () => {
          newController.panDisabled = false;
        })
      );
      subscriptions.push(
        this.subscribe('scene.preload.start', () => {
          newController.loading = true;
        })
      );
      subscriptions.push(
        this.subscribe('scene.preload.end', () => {
          newController.loading = false;
        })
      );
      this.switchController(newController);
      this.preMeasureModeConfig = {
        navigationMode: this.tourConfigService.navigationMode,
        renderHotSpots: this.engineConfig.renderHotSpots,
      };
      // Disable minimap
      this.tourConfigService.navigationMode = 'none';
      // Disable hotspots
      this.setHotSpotsDisabled(true);

      return this.renderer.toggleMeasureTool(this.tourConfigService.units);
    }
    // Custom controller cleanup
    subscriptions.forEach((s) => s.unsubscribe());
    subscriptions.length = 0;
    this.switchController(new GestureController());
    // Reenable minimap and hotspots
    if (this.preMeasureModeConfig) {
      this.tourConfigService.navigationMode = this.preMeasureModeConfig.navigationMode;
      this.setHotSpotsDisabled(!this.preMeasureModeConfig.renderHotSpots);
    } else {
      this.tourConfigService.navigationMode = 'full';
      this.setHotSpotsDisabled(false);
    }
    this.preMeasureModeConfig = null;

    this.renderer.toggleMeasureTool(false);
    return null;
  }

  public getCaptionData():
    | {
        boundingBox: {
          x1: number;
          x2: number;
          y1: number;
          y2: number;
        };
      }
    | {
        boundingBox: null;
      } {
    if (!__USE_VIDEO_EDITOR__)
      return {
        boundingBox: null,
      };

    const videoCaptionProgram = this.getProgram<VideoCaptionProgram>('VideoCaptionProgram');

    if (!videoCaptionProgram)
      return {
        boundingBox: null,
      };

    return videoCaptionProgram.getCaptionData();
  }

  /**
   * @returns Set<string> of all preloaded or visited scenes. Undefined if ScenePreloader is not active.
   */
  public getPreloadedScenes(): Set<string> | undefined {
    return this.scenePreloader?.getPreloadedScenes();
  }

  public setAppContext(appContext: AppContext): void {
    this.appContext = appContext;
  }

  // Force minimap to rerender for debug purposes
  public forceMinimapRerender(): void {
    this.emit('minimap.rerender.force');
  }

  public setAnalytics(analytics?: Analytics): void {
    this.analytics = analytics;

    if (!analytics) return;

    analytics.setGetCurrentSceneIdFunction(this.getActiveSceneKey);
    analytics.setGetCanvasSizeFunction(this.getCanvasSize);

    const { analyticsTimeHelper, analyticsSceneDragHelper, analyticsSceneInertiaHelper, analyticsSceneZoomHelper } =
      analytics;

    analyticsTimeHelper.unsubscribeAll();
    analyticsSceneDragHelper.unsubscribeAll();
    analyticsSceneInertiaHelper.unsubscribeAll();
    analyticsSceneZoomHelper.unsubscribeAll();

    analyticsTimeHelper.addSubscription(this.subscribe('scene.preload.start', analyticsTimeHelper.onSceneLoadStart));
    analyticsTimeHelper.addSubscription(this.subscribe('scene.preload.end', analyticsTimeHelper.onSceneLoaded));

    analyticsTimeHelper.addSubscription(
      this.subscribe('measuretool.load.start', analyticsTimeHelper.onMeasureToolLoadStart)
    );
    analyticsTimeHelper.addSubscription(
      this.subscribe('measuretool.load.end', analyticsTimeHelper.onMeasureToolLoaded)
    );
    analyticsTimeHelper.addSubscription(
      this.subscribe('measuretool.load.error', analyticsTimeHelper.onMeasureToolLoadError)
    );

    analyticsSceneDragHelper.addSubscription(
      this.subscribe('scene.interaction.start', analyticsSceneDragHelper.onSceneDragStart)
    );
    analyticsSceneDragHelper.addSubscription(
      this.subscribe('scene.interaction.update', analyticsSceneDragHelper.onSceneDragUpdate)
    );
    analyticsSceneDragHelper.addSubscription(
      this.subscribe('scene.interaction.end', analyticsSceneDragHelper.onSceneDragEnd)
    );

    analyticsSceneDragHelper.addSubscription(
      this.subscribe('scene.anim.start', analyticsSceneInertiaHelper.onSceneInertiaStart)
    );
    analyticsSceneDragHelper.addSubscription(
      this.subscribe('scene.anim.end', analyticsSceneInertiaHelper.onSceneInertiaEnd)
    );

    analyticsSceneZoomHelper.addSubscription(
      this.subscribe('scene.zoom.start', analyticsSceneZoomHelper.onSceneZoomStart)
    );
    analyticsSceneZoomHelper.addSubscription(
      this.subscribe('scene.zoom.update', analyticsSceneZoomHelper.onSceneZoomUpdate)
    );
    analyticsSceneZoomHelper.addSubscription(this.subscribe('scene.zoom.end', analyticsSceneZoomHelper.onSceneZoomEnd));
  }

  /**
   * Sets a new value for the `isVerificationNeeded` property.
   * @param isVerificationNeeded - `boolean`: new value to set.
   * @returns `void`
   */
  public setIsVerificationNeeded(isVerificationNeeded: boolean): void {
    this.isVerificationNeeded = isVerificationNeeded;
  }

  /**
   * Resets the initial scene to the default one.
   *
   * This is used by gated tour to reset the initial scene to the default one if tour is gated when the link contained a view url parameter.
   *
   * Is meant to be called before engine is started.
   * @returns `void`
   */
  public resetInitialSceneConfig(): void {
    const { tourConfig } = this.tourConfigService;

    if (tourConfig.defaultFirstScene) tourConfig.firstScene = tourConfig.defaultFirstScene;
    this.activeSceneConfig = this.tourConfigService.getInitialSceneConfig();
  }

  public render(): void {
    this.renderer.render();
  }

  public getProgram<TProgram extends AnyProgram>(programName: ProgramName): TProgram | undefined {
    return this.renderer.getProgram(programName);
  }

  public getHotSpotProgramByType(
    hotSpotType?: HotSpot2DType | HotSpot3DType
  ): HotSpotProgram3D | HotSpotProgram | undefined {
    return this.renderer.getHotSpotProgramByType(hotSpotType);
  }

  /** Get the constructed hotsSpots for current scene, this is not just the config but also includes data for webgl state */
  public getHotSpots(): (HotSpot3D | HotSpot)[] {
    return this.renderer.getHotSpots();
  }

  public setColorCorrectionSettings(settings: ColorCorrectionSettings): void {
    this.renderer.setColorCorrectionSettings(settings);
  }

  /**
   * Disables/enables hot-spots at runtime. If no hotSpotType provided - will affect all
   * the hot-spots. Can be called directly on the Engine instance
   */
  public setHotSpotsDisabled(flag = true, hotSpotType?: HotSpot2DType | HotSpot3DType): void {
    this.renderer.setHotSpotsDisabled(flag, hotSpotType);
  }

  /**
   * Enables high resolution mode in CubeProgram, renders max level tiles and sets the resolution to 3x canvas size
   * @param highRes - true to enable high resolution mode, false to disable
   * @param renderCallback - optional callback to be called after rendering the frame
   */
  public setHighResMode(highRes: boolean, renderCallback: (() => void) | null = null): void {
    this.renderer.setHighResMode(highRes, renderCallback);
  }

  public updateEquirectOptions(): void {
    this.renderer.updateEquirectOptions();
  }

  /** Update the watermark config, if the image is the same only the options will be changed,
   * if set to null, the watermark will be removed
   */
  public updateWatermarkConfig(watermarkConfig: WatermarkConfig | null): void {
    this.renderer.updateWatermarkConfig(watermarkConfig);
  }

  /**
   * @deprecated -- use RenderTarget
   */
  public drawToFramebufferTexture(outputTexture: WebGLTexture, drawcall: () => void): void {
    this.renderer.drawToFramebufferTexture(outputTexture, drawcall);
  }

  public enablePerformanceMeasurer(): void {
    this.renderer.enablePerformanceMeasurer();
  }

  public disablePerformanceMeasurer(): void {
    this.renderer.disablePerformanceMeasurer();
  }

  public startCustomPerformanceMeasurement(): void {
    this.renderer.startCustomPerformanceMeasurement();
  }

  public stopCustomPerformanceMeasurement(): void {
    this.renderer.stopCustomPerformanceMeasurement();
  }

  /**
   * @deprecated -- use RenderTarget class for a little bit optimized performance
   */
  public renderToTextureWithFunction(
    drawFunction: () => void,
    texture: WebGLTexture,
    useDepth = true,
    explicitSize?: { width: number; height: number }
  ): void {
    this.renderer.renderToTextureWithFunction(drawFunction, texture, useDepth, explicitSize);
  }

  public createEquirectForPano(assetPath: string, assetConfig: AssetConfig): Promise<HTMLImageElement> {
    return this.renderer.createEquirectForPano(assetPath, assetConfig);
  }

  public hideCaption(render = false, noAnimation = false): void {
    this.renderer.hideCaption(render, noAnimation);
  }

  public updateCaption(textParams: CaptionParams, render = false): void {
    this.renderer.updateCaption(textParams, render);
  }

  public onSceneMoved(): void {
    if (!this.isSceneMoveAnimated) {
      this.emit('scene.move.update', { pitch: this.pitch, yaw: this.yaw });
    }
  }

  // Helper functions

  protected setSceneTransition(inTransition: boolean): void {
    this.sceneTransition = inTransition;
    this.renderer.updateIsInTransition();
  }

  protected onGuidedViewingSceneMove(syncData: SyncData): void {
    this.yaw = syncData.yaw;
    this.pitch = syncData.pitch;
    this.zoomRatio = syncData.zoomRatio || this.zoomRatio;

    this.fov = linearScale(this.zoomRatio, [1, 0], Utils.getFovBoundary(this.getCanvasSize()));

    this.renderer.clampView();
    this.renderer.setOptimalLevel();
    this.render();
  }

  /** @todo move to controller? Why is this still triggered? */
  // because controller calls this method of a controller mixin and this is the implementation of the abstract method from controller mixin
  protected onZoomPano(zoomDelta: number, pinch = false): void {
    if (this.animatingTransition || this.isSceneMoveAnimated) return;

    this.changeZoom('fov', zoomDelta, pinch);
    this.renderer.setOptimalLevel();
    this.render();
  }

  protected onZoomFloorPlan(zoomDelta: number, pinch = false): void {
    this.changeZoom('zoom', zoomDelta, pinch);
    this.render();
  }

  // Renderer implementations

  protected preloadScenes(sceneKeys: string[]): void {
    this.scenePreloader?.preload(sceneKeys);
  }

  // private

  private loadInitialView(sceneConfig: SceneConfig): void {
    if (sceneConfig.view) {
      const [pitch, yaw] = sceneConfig.view;

      // Initial view in tour config is set in degrees
      this.pitch = pitch ? Utils.toRad(pitch) : 0;
      this.yaw = yaw ? Utils.toRad(yaw) : 0;

      // Because of the weird implementation in the legacy code, we need to counter the yaw offset for the initial view
      this.yaw -= Utils.toRad(sceneConfig.camera[3]);

      // Clamp view to limited pitch and fov values
      this.renderer.clampView();
    }
  }

  private setZoomRatio(nextFov: number): void {
    const fovBoundary = Utils.getFovBoundary(this.getCanvasSize());
    if (fovBoundary[0] === fovBoundary[1]) return;
    this.zoomRatio = linearScale(nextFov, fovBoundary, [1, 0]);
  }

  private changeZoom(type: 'fov' | 'zoom', byValue: number, pinch = false): void {
    let startValue = 0;
    let nextValue = 0;

    if (type === 'fov') {
      startValue = this.fov;
      nextValue = Utils.clampFov(startValue + Utils.toRad(byValue), this.getCanvasSize());
    } else {
      const increment = pinch ? byValue * 0.001 : clamp(byValue, -0.15, 0.15);
      startValue = this.zoomFloorPlan;
      nextValue = clamp(this.zoomFloorPlan + increment, 0, 1);
    }

    const zoomDirection = byValue > 0 ? 'up' : 'down';
    if (this.modeSwitchDirection !== zoomDirection) {
      this.modeSwitchDirection = zoomDirection;
      this.modeSwitchInitiatedTime = 0;
    }

    if (Date.now() - this.modeSwitchInitiatedTime > MODE_SWITCH_WAIT_TIMEOUT) {
      this.modeSwitchInitiatedTime = 0;
    }

    const valueDeltaIsZero = Math.abs(nextValue - startValue) < Number.EPSILON;

    // over-zooming  is happening, switch to the other mode (after a frustrating pause)
    if (valueDeltaIsZero) {
      if (zoomDirection === 'down') {
        if (this.modeSwitchInitiatedTime === 0) this.modeSwitchInitiatedTime = Date.now();
        if (Date.now() - this.modeSwitchInitiatedTime > MODE_SWITCH_WAIT_TIME) {
          this.emit('scene.move.zoom.min');
        }
      }
      if (zoomDirection === 'up') {
        if (this.modeSwitchInitiatedTime === 0) this.modeSwitchInitiatedTime = Date.now();
        if (Date.now() - this.modeSwitchInitiatedTime > MODE_SWITCH_WAIT_TIME) {
          this.emit('scene.move.zoom.max');
        }
      }

      return; // make sure not to emit events if no zooming is happening (to avoid spamming analytics)
    }

    if (type === 'fov') {
      // events only for pano zooming, floorplan zooming gets by with value change only
      const scenePos: ScenePos<Radian> = { pitch: this.pitch, yaw: this.yaw, fov: startValue };
      this.emit('scene.zoom.start', scenePos);
      this.emit('scene.move.start', scenePos);
    }

    // Pinch zoom
    if (pinch) {
      if (type === 'fov') {
        this.fov = nextValue;
        this.setZoomRatio(this.fov);
      } else {
        this.zoomFloorPlan = nextValue;
      }
      this.render();
      return;
    }

    // Wheel zoom
    this.startAnimation(
      'look',
      (progress) => {
        const currentValue = linearScale(progress, [0, 1], [startValue, nextValue]);
        if (type === 'fov') {
          this.fov = currentValue;
          this.setZoomRatio(this.fov);

          const newScenePos: ScenePos<Radian> = { pitch: this.pitch, yaw: this.yaw, fov: this.fov };
          this.emit('scene.zoom.update', newScenePos);
          this.emit('scene.move.update', newScenePos);
        } else {
          this.zoomFloorPlan = currentValue;
        }
        this.render();
      },
      () => {
        this.render();
        if (type === 'fov') {
          const lastScenePos: ScenePos<Radian> = { pitch: this.pitch, yaw: this.yaw, fov: this.fov };
          this.emit('scene.zoom.end', lastScenePos);
          this.emit('scene.move.end');
        }
      },
      'easeOutQuad',
      750
    );
  }

  private setTourEventSource(source: TourEventSource): void {
    this.tourEventSource = source;
    if (source === 'DOMEvents') {
      this.switchController(new GestureController());
    } else {
      this.switchController(new GuestController());
    }
    this.emit('tour.eventSource.change', source);
  }

  /**
   * Watch for config service changes and create related events.
   */
  private watchConfigChanges(): void {
    this.tourConfigService.watch(() => {
      const theme = this.tourConfigService.theme;
      this.renderer.updateColorFilter(theme);
      this.renderer.updateHotSpotTheme(theme);
      this.render();
    });

    this.tourConfigService.onChange('watermark', (config) => {
      this.updateWatermarkConfig(config || null);
    });

    this.tourConfigService.onChange('units', (units) => {
      this.renderer.handleMeasureUnitsChange(units);
    });

    /*
     * this emits are here for backward comparability of the Engine.
     * we can delete them after all package are migrated to TourConfigService
     */
    this.tourConfigService.watch(() => {
      this.emit('branding.theme.change', this.tourConfigService.theme);
    });

    this.tourConfigService.watch(() => {
      this.emit('branding.unit.change', this.tourConfigService.units);
    });

    this.tourConfigService.watch(() => {
      this.emit('branding.showRoomArea.change', this.tourConfigService.showRoomArea);
    });
  }
}

export default Engine;
