import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Utils } from '@g360/vt-engine';
import { isNumber } from '../common/utils';
const formatArea = (area, units, t) => {
    let stringArea = units === 'imperial' ? Utils.metricToImperialStr(area) : area.toFixed(2);
    if (stringArea.includes('.')) {
        stringArea = stringArea.replace(/\.?0+$/, '');
    }
    return (_jsxs(_Fragment, { children: [`${stringArea} ${units === 'imperial' ? t('units.ft') : t('units.m')}`, _jsx("sup", { children: "2" })] }));
};
function getDefaultSlots(config) {
    const { showTotalAreas, t, projectDataConfig, units } = config;
    const areas = projectDataConfig.areas;
    const list = [];
    if (!areas)
        return list;
    if (showTotalAreas && 'totalArea' in areas && isNumber(areas.totalArea) && areas.totalArea > 0) {
        list.push({
            label: t('property-info.total-area'),
            value: formatArea(areas.totalArea, units, t),
            key: 'total-area',
        });
    }
    if (showTotalAreas && 'statedSeparately' in areas && isNumber(areas.statedSeparately) && areas.statedSeparately > 0) {
        list.push({
            label: t('property-info.balconies-terraces'),
            value: formatArea(areas.statedSeparately, units, t),
            key: 'stated-separate-area',
        });
    }
    if (showTotalAreas &&
        'reducedHeadroomArea' in areas &&
        isNumber(areas.reducedHeadroomArea) &&
        areas.reducedHeadroomArea > 0) {
        list.push({
            label: t('property-info.reduced-headroom'),
            value: formatArea(areas.reducedHeadroomArea, units, t),
            key: 'reduced-headroom-area',
        });
    }
    return list;
}
function getGermanSlots(config) {
    const { showTotalAreas, t, projectDataConfig, units } = config;
    const areas = projectDataConfig.areas;
    const list = [];
    if (!areas)
        return list;
    if (showTotalAreas && 'livingArea' in areas && isNumber(areas.livingArea) && areas.livingArea > 0) {
        list.push({
            label: t('property-info.living-area'),
            value: formatArea(areas.livingArea, units, t),
            key: 'living-area',
        });
    }
    if (showTotalAreas && 'usableArea' in areas && isNumber(areas.usableArea) && areas.usableArea > 0) {
        list.push({
            label: t('property-info.usable-area'),
            value: formatArea(areas.usableArea, units, t),
            key: 'usable-area',
        });
    }
    return list;
}
function getInternationalSlots(config) {
    const { showTotalAreas, t, projectDataConfig, units } = config;
    const areas = projectDataConfig.areas;
    const list = [];
    if (!areas)
        return list;
    if (showTotalAreas && 'totalArea' in areas && isNumber(areas.totalArea) && areas.totalArea > 0) {
        list.push({
            label: t('property-info.total-area'),
            value: formatArea(areas.totalArea, units, t),
            key: 'total-area',
        });
    }
    if (showTotalAreas && 'statedSeparately' in areas && isNumber(areas.statedSeparately) && areas.statedSeparately > 0) {
        list.push({
            label: t('property-info.balconies-terraces'),
            value: formatArea(areas.statedSeparately, units, t),
            key: 'stated-separate-area',
        });
    }
    return list;
}
export function getPropertyAreasInfoSlots(config) {
    const { showTotalAreas, t, projectDataConfig, units } = config;
    const areaStandard = projectDataConfig.floorplanStandard;
    const areas = projectDataConfig.areas;
    if (!areas)
        return [];
    switch (areaStandard) {
        case 'default':
            return getDefaultSlots(config);
        case 'german':
            return getGermanSlots(config);
        case 'international':
            return getInternationalSlots(config);
        case undefined:
            if (showTotalAreas && 'totalArea' in areas && isNumber(areas.totalArea)) {
                return [
                    {
                        label: t('property-info.total-area'),
                        value: formatArea(areas.totalArea, units, t),
                        key: 'total-area',
                    },
                ];
            }
            return [];
        default:
            throw new Error('Invalid area standard');
    }
}
export function getLegacyPropertyAreasInfoSlots(config) {
    const list = [];
    const { showTotalAreas, t, projectDataConfig, units } = config;
    const totalArea = projectDataConfig === null || projectDataConfig === void 0 ? void 0 : projectDataConfig.totalArea;
    const totalSeparateArea = projectDataConfig === null || projectDataConfig === void 0 ? void 0 : projectDataConfig.totalSeparateArea;
    const reducedHeadroomArea = projectDataConfig === null || projectDataConfig === void 0 ? void 0 : projectDataConfig.reducedHeadroomArea;
    const fpStandard = projectDataConfig === null || projectDataConfig === void 0 ? void 0 : projectDataConfig.floorplanStandard;
    const noStandard = fpStandard === undefined;
    const germanStandard = fpStandard === 'german';
    const defaultStandard = fpStandard === 'default';
    const internationalStandard = fpStandard === 'international';
    if (showTotalAreas && isNumber(totalArea)) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        let labelName = '';
        if (noStandard)
            labelName = t('property-info.total-area');
        else if (defaultStandard || internationalStandard)
            labelName = t('property-info.approx-total-area');
        else if (germanStandard)
            labelName = t('property-info.living-area');
        list.push({
            label: labelName,
            value: formatArea(totalArea, units, t),
            key: 'total-area',
        });
    }
    if (showTotalAreas && isNumber(totalSeparateArea) && (germanStandard || defaultStandard || internationalStandard)) {
        let labelName = '';
        if (germanStandard)
            labelName = t('property-info.usable-area');
        else if (defaultStandard || internationalStandard)
            labelName = t('property-info.balconies-terraces');
        list.push({
            label: labelName,
            value: formatArea(totalSeparateArea, units, t),
            key: 'total-usable-area',
        });
    }
    if (showTotalAreas && isNumber(reducedHeadroomArea) && defaultStandard) {
        list.push({
            label: t('property-info.reduced-headroom'),
            value: formatArea(reducedHeadroomArea, units, t),
            key: 'reduced-headroom-area',
        });
    }
    return list;
}
