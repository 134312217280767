/* eslint-disable no-await-in-loop,no-continue */
import type {
  AutoPlayFrame,
  AutoplayProgress,
  AutoPlaySceneFrames,
  AutoplayStatus,
  CaptionTextFrame,
  ScenePos,
} from '@g360/vt-types';
import { lerp } from '@g360/vt-utils';
import noop from 'lodash/noop';

import SimpleAnimator from '../common/SimpleAnimator';
import Utils from '../common/Utils';
import BaseController from './BaseController';
import { sleep } from './utils';

type Frames = {
  scenes: AutoPlaySceneFrames[];
  texts?: CaptionTextFrame[];
};

export default class AutoplayController extends BaseController {
  private readonly animationFixedTimeStep: number | undefined;
  private readonly withScenePreloading: boolean | undefined;

  private frameRunningDuration = new Map<AutoPlayFrame, number>(); // frame => sum of all previous frame durations
  private frameRunningDurationInclusive = new Map<AutoPlayFrame, number>(); // frame => sum of all previous frame durations + this frame
  private scenes: AutoPlaySceneFrames[];
  private totalDuration = 0;
  private _textFadeTime = 200; // 200;
  private autoplayText: CaptionTextFrame[] = [];
  private _activeTextFrame = -1;
  private _enableTextFrames = true;
  private callbackStop?: () => Promise<void> = undefined; // internally used
  private callbackPause?: () => Promise<void> = undefined; // internally used
  private callbackNotify?: (args: { progress: AutoplayProgress; status: AutoplayStatus }) => void = undefined; // externally used: 1 lucky subscriber can get notified on every progress change
  private callbackTransitionOver?: () => void; // internally used
  private _progress: AutoplayProgress = { sceneIdx: 0, frameIdx: 0, progressTotal: 0, progressFrame: 0 };
  private _status: 'stopped' | 'playing' | 'paused' = 'stopped';
  private _playbackSpeed = 1;
  private inTransition = false;

  constructor(
    frames: Frames | AutoPlaySceneFrames[],
    playbackSpeed = 1,
    options?: { fixedTimeStep?: number; withPreloading: boolean }
  ) {
    super();
    // backwards compatibility
    if (!Array.isArray(frames)) {
      this.scenes = [...frames.scenes];
      if (frames.texts) {
        this.updateTextFrames(frames.texts);
      }
    } else {
      this.scenes = [...frames];
    }
    this._playbackSpeed = playbackSpeed;
    this.animationFixedTimeStep = options?.fixedTimeStep;
    this.withScenePreloading = options?.withPreloading;

    this.calculateDurations();

    window?.addEventListener('resize', () => {
      const activeFrame = this.getActiveTextFrame();

      if (activeFrame) {
        this.setCaptionText({
          text: activeFrame.text,
          align: activeFrame.align,
          font: activeFrame.font,
          lightMode: activeFrame.lightMode,
          animationDuration: 0,
          fontSize: activeFrame.fontSize,
        });
      }
    });
  }

  public get progress() {
    return this._progress;
  }

  public get status() {
    return this._status;
  }

  public get playbackSpeed() {
    return this._playbackSpeed;
  }

  public set playbackSpeed(speed: number) {
    this._playbackSpeed = speed;
  }

  public enableTextFrames = (enable: boolean) => {
    this._enableTextFrames = enable;

    if (!this._enableTextFrames) {
      this.hideCaptionText(true, true);
    } else {
      const activeFrame = this.getActiveTextFrame();

      if (activeFrame) {
        this.setCaptionText(
          {
            text: activeFrame.text,
            align: activeFrame.align,
            font: activeFrame.font,
            lightMode: activeFrame.lightMode,
            animationDuration: 0,
            fontSize: activeFrame.fontSize,
          },
          true
        );
      }
    }
  };

  private get sceneKey(): string {
    return this.getEngineState().sceneKey;
  }

  public async play() {
    if (this.status === 'playing') {
      throw new Error('AutoplayController::play:Cannot play while playing, stop/pause first!');
    }

    if (this.scenes.length === 0) {
      this._status = 'stopped';
      this.notify();
      throw new Error('AutoplayController::play:no scenes to play!');
    }

    this.prepareAndPreloadScenes(); // start preloading scenes in the background -- when PLAY starts
    this._status = 'playing';
    this.playText();
    this.notify();

    for (let sceneIdx = this._progress.sceneIdx; sceneIdx < this.scenes.length; sceneIdx += 1) {
      this._progress.sceneIdx = sceneIdx;
      const previousScene = this.scenes[(this.scenes.length + sceneIdx - 1) % this.scenes.length];
      const scene = this.scenes[sceneIdx];
      const { sceneKey, frames } = scene;
      for (let frameIdx = this._progress.frameIdx; frameIdx < frames.length; frameIdx += 1) {
        this._progress.frameIdx = frameIdx;
        const previousFrame =
          frameIdx > 0 ? frames[frameIdx - 1] : previousScene.frames[previousScene.frames.length - 1];
        const frame = frames[frameIdx];

        // transitions frames 100% coincide with the need for scene change
        if (frame.transition && sceneKey && sceneKey !== this.sceneKey) {
          const transitionDuration = frame.duration / this._playbackSpeed;
          this.inTransition = true;

          // Since next scene can start at a different rotation than which the previous scene ended
          // we have to supply the starting yaw for the next scene, such that it can be
          // changed without any jumps in the rotation
          const nextSceneStartYaw = frame.position.yaw;

          // changing-scene and ticking-progress-while-it-does-that should take exactly the same time (in perfect world)
          // if the change scene is tardy (slow internet) then we will still wait for it to finish, but autoplay progress won't increase
          // it's not slow internet, change scene takes few (up to 12) frames longer than it should, given the exact time for both functions
          await Promise.all([
            this.changeScene(sceneKey, { duration: transitionDuration, startYaw: nextSceneStartYaw }),
            this.animateChangeSceneWaiting(frame, transitionDuration),
          ]);

          this.prepareAndPreloadScenes(); // start preloading scenes in the background -- when transition is over (scene change stopped preloading)

          // using custom callback to notify when transition is over,
          // not putting all awaits in the "this.stoppable"
          // because we can't stop the actual transition animation but this way at least will keep the progress counting
          // ("this.stoppable" will stop the progress counting while the actual transition keeps going)
          this.inTransition = false;
          if (this.callbackTransitionOver) {
            this.callbackTransitionOver();
            this.callbackTransitionOver = undefined;
          }

          this._progress.progressTotal = this.getFrameRunningDurationInclusive(frame) / this.totalDuration;
          this._progress.progressFrame = 0;

          this.playText();
          this.notify();

          continue; // no need for regular camera animation
        }
        // skip instant frames (next frame will correctly animate anyway, no need for camera repositioning without animation)
        if (frame.duration === 0) continue;
        const posFrom: ScenePos = {
          yaw: Utils.toRad(previousFrame.position.yaw),
          pitch: Utils.toRad(previousFrame.position.pitch),
        };

        const posTo: ScenePos = {
          yaw: Utils.toRad(frame.position.yaw),
          pitch: Utils.toRad(frame.position.pitch),
        };
        await this.stoppable(this.animateCamera(posFrom, posTo, this._progress.progressFrame, frame));

        // frame is over, reset frame progress and update total progress
        this._progress.progressTotal = this.getFrameRunningDurationInclusive(frame) / this.totalDuration;
        this._progress.progressFrame = 0;
        this.playText();
        this.notify();
      }

      this._progress.frameIdx = 0; // need to reset frame index manually, for loop couldn't be bothered with it (to be able to support resuming)
      this.playText();
      this.notify();
    }

    this._status = 'stopped';
    this.playText();
    this.notify();

    const oldCallbackPause = this.callbackPause;
    this.callbackPause = () => {
      throw new Error("AutoplayController::play Paused during 'autoplay.sequence.end' event");
    };
    try {
      await this.emit('autoplay.sequence.end');
    } catch (e) {
      this.callbackPause = oldCallbackPause;
      throw e;
    }

    this.callbackPause = oldCallbackPause;
  }

  public async stop() {
    if (this.inTransition) {
      await new Promise<void>((resolve) => {
        this.callbackTransitionOver = () => resolve();
      });
    }
    if (this.inTransition) {
      return;
    }

    if (this.callbackStop) {
      this.callbackStop();
    }
    this.resetProgress();
    this.callbackStop = undefined;
    await this.changeCamera(this.getEngineState());
    this._status = 'stopped';
    this.resetAnimation();
    await sleep(1);
    this.notify();
  }

  public async pause() {
    if (this.inTransition) {
      await new Promise<void>((resolve) => {
        this.callbackTransitionOver = () => resolve();
      });
    }
    if (this.inTransition) {
      return;
    }

    if (this.callbackPause) {
      this.callbackPause();
    }
    this.callbackPause = undefined;
    await this.changeCamera(this.getEngineState());
    this._status = 'paused';
    this.resetAnimation();
    await sleep(1);
    this.playText();
    this.notify();
  }

  setScenes(newScenes: AutoPlaySceneFrames[]) {
    if (this.status === 'playing') {
      this.stop();
    }
    this.scenes = [...newScenes];
    this.calculateDurations();
  }

  /**
   * Explicitly starting instant animation to overwrite existing one
   * This is necessary for video editor, where there is no other controller that starts when autoplay is stopped
   */
  resetAnimation() {
    this.startAnimation('look', noop, noop, 'linear', 0);
  }

  public subscribe(subscriber: (payload: { progress: AutoplayProgress; status: AutoplayStatus }) => void) {
    this.callbackNotify = subscriber;
  }

  public resetProgress() {
    this._progress = { sceneIdx: 0, frameIdx: 0, progressTotal: 0, progressFrame: 0 };
  }

  /**
   * Seeking to a specific progress % in the whole autoplay
   * @param progress
   */
  public async seekTotalProgress(progress: number) {
    const newProgress = this.findProgressPoint(progress);
    await this.seek(newProgress);
  }

  /**
   * Seeking to a specific scene, frame and progress % in the frame
   * @param sceneIdx
   * @param frameIdx
   * @param progressFrame
   */
  public async seekScene(sceneIdx: number, frameIdx = 0, progressFrame = 0) {
    if (this._status === 'playing') {
      throw new Error('AutoplayController::seekScene:Cannot seek while playing, stop/pause first!');
    }
    await this.seek({ sceneIdx, frameIdx, progressTotal: 0, progressFrame });
  }

  public updateTextFrames(captionFrames: CaptionTextFrame[], refresh = false) {
    this.autoplayText = captionFrames.map((frame) => ({ ...frame, endTime: frame.endTime - this._textFadeTime }));

    if (refresh) {
      const activeFrame = this.getActiveTextFrame();

      if (activeFrame) {
        this.setCaptionText(
          {
            text: activeFrame.text,
            align: activeFrame.align,
            font: activeFrame.font,
            lightMode: activeFrame.lightMode,
            animationDuration: 0,
            fontSize: activeFrame.fontSize,
          },
          true
        );
        this.notify();
      } else {
        this.hideCaptionText(true);
        this.playText();
        this.notify();
      }
    }
  }

  public getActiveTextFrame() {
    const currentProgress = this.totalDuration * this._progress.progressTotal * this._playbackSpeed;

    return this.autoplayText.find(
      ({ startTime, endTime }) => startTime <= currentProgress && endTime >= currentProgress
    );
  }

  public prepareAndPreloadScenes() {
    if (!this.withScenePreloading) return;

    const sceneNames = this.scenes.map((scene) => scene.sceneKey);

    const skipFirstNum = this.progress.sceneIdx; // skip already played scenes
    const scenesFollowing = sceneNames.slice(skipFirstNum + 1); // don't include current scene
    const scenesPreceding = sceneNames.slice(0, skipFirstNum);

    const scenes = [...scenesFollowing, ...scenesPreceding];
    const uniqueScenes = Array.from(new Set(scenes));
    this.preloadScenes(uniqueScenes);
  }

  private async seek(newProgress: AutoplayProgress) {
    const sceneIdx = newProgress.sceneIdx;
    const frameIdx = newProgress.frameIdx;
    const scene = this.scenes[sceneIdx];
    const previousScene = this.scenes[(this.scenes.length + sceneIdx - 1) % this.scenes.length];
    if (!scene) return;

    const frame = scene.frames[frameIdx];
    const progressFrame = newProgress.progressFrame;
    const easing = frame.easing;
    const previousFrame =
      frameIdx > 0 ? scene.frames[frameIdx - 1] : previousScene.frames[previousScene.frames.length - 1];
    const easedProgress = SimpleAnimator.getEasedValue(progressFrame, easing);

    if (this.sceneKey !== scene.sceneKey) {
      await this.changeScene(scene.sceneKey, { duration: 0 });
    }

    const { fov } = this.getEngineState();

    const posFrom: ScenePos = {
      yaw: Utils.toRad(previousFrame.position.yaw),
      pitch: Utils.toRad(previousFrame.position.pitch),
    };
    const posTo: ScenePos = {
      yaw: Utils.toRad(frame.position.yaw),
      pitch: Utils.toRad(frame.position.pitch),
    };
    const easedPos = {
      yaw: lerp(posFrom.yaw, posTo.yaw, easedProgress),
      pitch: lerp(posFrom.pitch, posTo.pitch, easedProgress),
      fov,
    };

    await this.changeCamera({ ...easedPos });

    this._progress = newProgress;
    this._progress.progressTotal =
      (this.getFrameRunningDuration(frame) + frame.duration * progressFrame) / this.totalDuration;

    this.playText();
    this.notify();
    this.prepareAndPreloadScenes();
  }

  private notify() {
    if (this.callbackNotify)
      this.callbackNotify({
        progress: this.progress,
        status: this.status,
      });
  }

  private playText() {
    const activeFrame = this.getActiveTextFrame();

    if (!activeFrame || !this._enableTextFrames) {
      if (this._activeTextFrame !== -1) {
        this._activeTextFrame = -1;
        this.hideCaptionText(true);
      }
      return;
    }

    const activeFrameIndx = this.autoplayText.indexOf(activeFrame);

    if (this._activeTextFrame !== activeFrameIndx) {
      this._activeTextFrame = activeFrameIndx;

      this.setCaptionText({
        text: activeFrame.text,
        align: activeFrame.align,
        font: activeFrame.font,
        lightMode: activeFrame.lightMode,
        animationDuration: this._textFadeTime,
        fontSize: activeFrame.fontSize,
      });
    }
  }

  private stoppable(play: Promise<any>) {
    const playController = new Promise((_, reject) => {
      this.callbackStop = async () => {
        this.resetProgress();
        reject();
      };

      this.callbackPause = async () => {
        reject();
      };
    });

    return Promise.race([play, playController]);
  }

  private animateCamera(fromPos: ScenePos, toPos: ScenePos, startFromProgress: number, frame: AutoPlayFrame) {
    const duration = frame.duration / this._playbackSpeed;
    const easing = frame.easing;
    const progressRemaining = 1 - startFromProgress; // %
    const durationRemaining = progressRemaining * duration; // milliseconds
    const { fov } = this.getEngineState();

    return new Promise<boolean>((resolve) => {
      // This animation function (from Engine mixin Animator.ts) is used only to get ticks at browsers framerate.
      // It uses animation type 'look' for it, so that any other animation or camera movement will stop it.
      // On each tick of this animation camera is moved to new position, but it is not animated, it is instant
      this.startAnimation(
        'look',
        (tickerProgress) => {
          const newProgress = startFromProgress + progressRemaining * tickerProgress;
          const easedProgress = SimpleAnimator.getEasedValue(newProgress, easing);
          const easedPos = {
            yaw: lerp(fromPos.yaw, toPos.yaw, easedProgress),
            pitch: lerp(fromPos.pitch, toPos.pitch, easedProgress),
            fov, // don't animate FOV, just use current one
          };
          this._progress.progressTotal =
            (this.getFrameRunningDuration(frame) + duration * newProgress) / this.totalDuration;
          this._progress.progressFrame = newProgress;
          this.playText();
          this.notify();
          this.changeCamera({ ...easedPos, keepOldAnimation: true });
        },
        () => {
          resolve(true);
        },
        'linear',
        durationRemaining,
        this.animationFixedTimeStep
      );
    });
  }

  private animateChangeSceneWaiting(frame: AutoPlayFrame, duration: number) {
    return new Promise((resolve) => {
      this.startAnimation(
        'look',
        (tickerProgress) => {
          this._progress.progressTotal =
            (this.getFrameRunningDuration(frame) + duration * tickerProgress) / this.totalDuration;
          this._progress.progressFrame = tickerProgress;
          this.playText();
          this.notify();
        },
        () => {
          resolve(true);
        },
        'linear',
        duration
      );
    });
  }

  private calculateDurations() {
    let runningDuration = 0;
    this.frameRunningDuration.clear();
    this.frameRunningDurationInclusive.clear();
    this.totalDuration = 0;

    for (let i = 0; i < this.scenes.length; i += 1) {
      const scene = this.scenes[i];
      for (let j = 0; j < scene.frames.length; j += 1) {
        const frame = scene.frames[j];
        this.frameRunningDuration.set(frame, runningDuration);
        runningDuration += frame.duration / this._playbackSpeed;
        this.totalDuration += frame.duration / this._playbackSpeed;
        this.frameRunningDurationInclusive.set(frame, runningDuration);
      }
    }
  }

  private getFrameRunningDuration(frame: AutoPlayFrame) {
    const time = this.frameRunningDuration.get(frame);
    if (time === undefined) throw new Error('frame not found in frameRunningDuration, this should never happen'); // @todo -- rem this check
    return time;
  }

  private getFrameRunningDurationInclusive(frame: AutoPlayFrame) {
    const time = this.frameRunningDurationInclusive.get(frame);
    if (time === undefined)
      throw new Error('frame not found in frameRunningDurationInclusive, this should never happen'); // @todo -- rem this check
    return time;
  }

  private findProgressPoint(progressTotal: number): AutoplayProgress {
    if (progressTotal < 0 || progressTotal > 1) {
      throw new Error('Total progress must be between 0 and 1');
    }

    const targetDuration = this.totalDuration * progressTotal * this._playbackSpeed;
    let runningDuration = 0;

    for (let i = 0; i < this.scenes.length; i += 1) {
      const scene = this.scenes[i];
      for (let j = 0; j < scene.frames.length; j += 1) {
        const frame = scene.frames[j];
        runningDuration += frame.duration;

        if (runningDuration >= targetDuration) {
          const frameProgress = (targetDuration - (runningDuration - frame.duration)) / frame.duration;
          return { sceneIdx: i, frameIdx: j, progressTotal, progressFrame: frameProgress };
        }
      }
    }

    // totalProgress === 1
    return {
      sceneIdx: this.scenes.length - 1,
      frameIdx: this.scenes[this.scenes.length - 1]?.frames.length - 1,
      progressTotal,
      progressFrame: 1,
    };
  }
}
