import { useCallback, useRef } from 'react';
const useDoubletapGuard = (onDoubleTap, tapThreshold = 400) => {
    const lastTapRef = useRef(0);
    const lastToggleRef = useRef(0);
    const singleTapTimeoutRef = useRef(null);
    const handleInteraction = useCallback(() => {
        const currentTime = new Date().getTime();
        const timeSinceLastTap = currentTime - lastTapRef.current;
        if (singleTapTimeoutRef.current) {
            clearTimeout(singleTapTimeoutRef.current);
            singleTapTimeoutRef.current = null;
        }
        if (timeSinceLastTap > 10 && timeSinceLastTap < tapThreshold) {
            onDoubleTap();
            lastToggleRef.current = currentTime;
        }
        lastTapRef.current = currentTime;
    }, [onDoubleTap, tapThreshold]);
    return handleInteraction;
};
export default useDoubletapGuard;
